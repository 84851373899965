module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog hello-42 - Join Stories","short_name":"Blog","start_url":"/","background_color":"#141414","theme_color":"#FFF","display":"minimal-ui","icons":[{"src":"/icons/logo-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/logo-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons/logo-180x180.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"content":{"padding":"0px","top":0,"left":0,"right":0,"bottom":0},"overlay":{"zIndex":1001}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
